// Typography
.h1 {
    @apply text-4xl font-extrabold tracking-tighter;
}

.h2 {
    @apply text-3xl font-extrabold tracking-tighter;
}

.h3 {
    @apply text-3xl font-extrabold;
}

.h4 {
    @apply text-2xl font-extrabold tracking-tight;
}

@screen md {
    .h1 {
        @apply text-5xl;
    }

    .h2 {
        @apply text-4xl;
    }
}

// Buttons
.btn,
.btn-lg,
.btn-sm,
.btn-xs {
    @apply font-medium text-sm inline-flex items-center justify-center border border-transparent rounded leading-5 shadow-sm transition duration-150 ease-in-out;
}

.btn {
    @apply px-6 py-2;
}

.btn-lg {
    @apply px-4 py-3;
}

.btn-sm {
    @apply px-2 py-1;
}

.btn-xs {
    @apply px-2 py-0.5;
}

// Forms
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {
    @apply text-sm text-slate-800 bg-white border;
   
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox {
    @apply rounded;
}

.form-input,
.form-textarea,
.form-multiselect,
.form-select {
    @apply leading-5 py-2 px-3 border-slate-200 hover:border-slate-300 focus:border-indigo-300 shadow-sm;
}

.form-input,
.form-textarea {
    @apply placeholder-slate-400;
}

.form-select {
    @apply pr-10;
}

.form-checkbox,
.form-radio {
    @apply text-[#0f1e35] border border-slate-300;
}
.ml-shoppingCart{
    margin-left:5rem
}

.product-availability {
    margin: 10px auto 0;
    margin-left: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    width: 95px;
    z-index: 1;
}
.stockStatus i {
    margin-top: 1px !important;
    padding-left: 10px !important;
    padding-right: 0.5rem !important;
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important;
}
.q_section {
    -webkit-flex: 0 0 30%;
    flex: 0 0 30%;
    min-width: 50%;
}
.product-title {
    color: #332d97;
    font-weight: bold;
}

.card_details p {
    white-space: nowrap;
    margin-bottom: 5px;
}
.fontsize{
    font-size: 12px;
}
.mb-bottom{
    margin-bottom: 1rem;
}
.width{
    width: 10rem;
}
// .themeColor{
//     color: var(--commanColorHover); 
// }
// .themeColor-hover{
//     color: #ff7300; 
// }
.card_details p label {
    display: inline-block;
    font-weight: 600;
    color: #000;
    position: relative;
    text-transform: capitalize;
}

.card_details span {
    font-weight: bold;
    padding: 0;
}

.card_details .ml-auto {
    margin-left: auto !important;
}
.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.product-availability .stockStatus.in-stock {
    background: #5aab19;
    color: #fff;
}
.cart_item {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding: 10px 0;

}
.backorder{
    margin-right:-4px;
}
/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
table thead tr
{
    background: var(--tabletheadColor);
    font-size: 14px;
    font-weight: 700;
    color: var(--commanColor);
    
}
.fa-eye:before{
    color: var(--commanColor);
}
.bg-sidebar{
    background: var(--commanColor);
}
.bg-sidebar-text{
    color: var(--commanColor);
    font-size:1.6rem;
}
.demopage img{
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.round-shadow{
 box-shadow: 0 0px 20px -7px var(--commanColor)!important;
    ;
}

.center-div
{
box-shadow: 0 0px 14px -7px var(--commanColor) !important;
}
.swal2-styled.swal2-confirm{
    background-color: var(--commanColor);
}
@media (max-width: 527px) {
    .center-div{
        display: flex;
        justify-content: center;
    }
    }