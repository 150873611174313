@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=fallback");

@tailwind base;
@tailwind components;

@import "./variables.scss";

// Additional styles
@import "additional-styles/utility-patterns.scss";
@import "additional-styles/range-slider.scss";
// @import 'additional-styles/toggle-switch.scss';
// @import 'additional-styles/flatpickr.scss';
@import "additional-styles/theme.scss";

@tailwind utilities;

// .xlsx-hover{
//     margin-top: -20px;
//     margin-left: 40px;
// }

.db-helth-table {
    tr td:last-child {
        text-align: left !important;
    }
}

.main-comman-color {
    background-color: #0f1e35;
}

.not-allowed {
    padding: 10px 20px;
    // background-color: #1e293b;
    // color: white;
    border: none;
    border-radius: 2px;
    cursor: not-allowed !important;
}

.chart-min-height {
    height: 100% !important;
}

.chart-min-height {
    canvas {
        height: 100% !important;
        // width: 1300px !important;
        // width: 500px !important;
    }
}

.radar-chart-sec canvas {
    height: 362px !important;
}

.Charts-date input {
    height: 35px;
    width: 140px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    // padding-left: 11px;
    // min-width: 247px;
    outline: none;
    // padding-right: 22px;
    margin-right: 17px;
}

.table-height-fix {
    height: 403px;
    overflow: auto;
}

.table-4-height {
    height: 403px;
}

.radar-chart-sec canvas {
    height: 362px !important;
}

// .custom-datepicker{
//     background: url(../../public/date-pic-icon.png);
//     //     background-position: center;
//     //     background-repeat: no-repeat;
//     //     background-size: contain;
//     //     right: 0;
//     //     position: absolute;
//     //     content: "";
// }

.min-hight-text-area {
    min-height: 150px;
}

.card-bg-light {
    background: #fff;
}

.login-back-color {
    // background: var(--commanColorHoverLight);
    background: var(--commanColorHoverLight);
}

.logo-img {
    img {
        width: 50%;
        display: flex;
        justify-content: center;
    }
}

.pad-login-add {
    padding: 50px 50px;
}

.login-card {
    background: #fff;
    box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
    border-radius: 5px;
}

.ui-width {
    max-width: 50%;
    //   height: 50%;
}

.margin-left-25 {
    margin-left: 25%;
}

.icon-color-new {
    i {
        color: var(--commanColorHover) !important;
    }

    svg {
        color: var(--commanColorHover) !important;
    }
}
.icon-color-chart {
    i {
        color: #0f1e35 !important;
    }

    svg {
        color: #0f1e35 !important;
    }
}

.font-size-400 {
    font-weight: 400;
}

.text-white:hover {
    color: var(--commanColorHover) !important;
}

.text-slate-200 {
    color: #fff;
}

.commanColorbtn {
    background: var(--commanColor) !important;

    &:hover {
        color: #fff !important;
    }
}

.new-color {
    color: var(--commanColor) !important;
}

.new-color-hover {
    color: var(--commanColorHover) !important;
}

.text-slate-200:hover {
    color: var(--commanColorHover) !important;
}

.hover\:text-white:hover {
    color: var(--commanColorHover) !important;
}

.themeColor-hover:hover {
    color: var(--commanColorHover) !important;
}

.table-tr-width table thead tr th td div,
.table-tr-width table tbody tr td {
    width: 10%;
}

.border-picker svg {
    color: var(--commanColor) !important;
}

.dashboard-date-range {
    input {
        border-radius: 5px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        -ms-border-radius: 5px;
        -o-border-radius: 5px;
        padding-left: 11px;
        min-width: 247px;
        outline: none;
        padding-right: 22px;
    }

    &:focus {
        outline: none;
        box-shadow: none;
        border-color: #11769f;
    }
}

.tox-tinymce {
    border-radius: 0;
}

.buttonLeft {
    min-width: 4rem;
}

.tiny-editor-sec {
    border: 1px solid #00769f;
    border-radius: 5px;
}

.left-arrow-btn {
    display: flex;
    justify-content: space-between;
}

.margin-left {
    margin-left: 0;
}

.contact-details-width {
    min-width: 150px;
}

.search-date-btn {
    .form-group {
        width: 300px;
    }
}

// shopping-cart-table-sec
.shopping-cart-table-sec .card_details span {
    min-width: 75px;
    display: inline-block;
}

.shopping-cart-table-sec table thead tr th:nth-child(1),
.shopping-cart-table-sec table tbody tr td:nth-child(1) {
    text-align: left;
    width: 20%;
}

.shopping-cart-table-sec table thead tr th:nth-child(1) div {
    text-align: left;
}

.shopping-cart-table-sec table thead tr th:nth-child(2),
.shopping-cart-table-sec table tbody tr td:nth-child(2) {
    text-align: left;
    width: 45%;
}

.shopping-cart-table-sec table thead tr th:nth-child(2) div {
    text-align: left;
}

.shopping-cart-table-sec table thead tr th:nth-child(3),
.shopping-cart-table-sec table tbody tr td:nth-child(3) {
    text-align: center;
    width: 12%;
}

.shopping-cart-table-sec table thead tr th:nth-child(3) div {
    text-align: center;
}

.shopping-cart-table-sec table thead tr th:nth-child(4),
.shopping-cart-table-sec table tbody tr td:nth-child(4) {
    text-align: center;
    width: 11%;
}

.shopping-cart-table-sec table thead tr th:nth-child(4) div {
    text-align: center;
}

.shopping-cart-table-sec table thead tr th:nth-child(5),
.shopping-cart-table-sec table tbody tr td:nth-child(5) {
    text-align: right;
    width: 12%;
}

.shopping-cart-table-sec table thead tr th:nth-child(5) div {
    text-align: right;
}
// .shopping-cart-table-sec table thead tr th:nth-child(7) div {
//     text-align: right;
// }

.shopping-cart-table-sec .cart-image1 {
    position: relative;
}

.shopping-cart-table-sec .product-availability {
    width: 100px;
    position: absolute;
    top: 0;
    left: 0;
}

.text-blue-500,
.text-blue-600 {
    color: var(--commanColorHover);
}

.text-blue-500 {
    font-weight: bold !important;
}

.hover\:text-blue-700:hover {
    color: #11769f;
}

.border-picker {
    border-color: #11769f;
}

.bg-blue-500 {
    background-color: #008ebf;
    background: var(--commanColor);
}

// .bg-blue-100,
// .hover\:bg-gray-100:hover {
//     background-color: #5b85c90f;
// }

button {
    padding: 0 15px;
}

.w-12 {
    width: 35px;
}

.h-12 {
    height: 35px;
}

.lg:w-10 {
    width: 35px;
}

.lg:h-10 {
    height: 35px;
}

.flex-none {
    display: flex;
}

.fixed_header {
    tbody {
        display: block;
        max-height: 390px;
        overflow: auto;
        width: 100%;
    }
}

.table-tr-rfq table thead tr th:nth-child(4) div,
.table-tr-rfq table tbody tr td:nth-child(4) {
    text-align: center;
}

.table-tr-search table thead tr th:nth-child(3) div,
.table-tr-search table tbody tr td:nth-child(3) {
    text-align: center;
}

.table-tr-search1 table thead tr th:nth-child(4) div,
.table-tr-search1 table tbody tr td:nth-child(4) {
    text-align: center;
}

.table-tr-search2 table thead tr th:nth-child(5) div,
.table-tr-search2 table tbody tr td:nth-child(5) {
    text-align: center;
}

.table-tr-pvbu table thead tr th:nth-child(2) div,
.table-tr-pvbu table tbody tr td:nth-child(2) {
    text-align: center;
}

.table-tr-pvbu1 table thead tr th:nth-child(3) div,
.table-tr-pvbu1 table tbody tr td:nth-child(3) {
    text-align: center;
}

.table-tr-pvbu2 table thead tr th:nth-child(4) div,
.table-tr-pvbu2 table tbody tr td:nth-child(4) {
    text-align: left;
}

.table-tr-pvbu3 table thead tr th:nth-child(5) div,
.table-tr-pvbu3 table tbody tr td:nth-child(5) {
    text-align: left;
}

.table-tr-custom:nth-child(odd) {
    background: #fff;
    border-bottom: 1px solid #00000014;

    &:last-child {
        border-bottom: none;
    }
}

.table-tr-custom:nth-child(even) {
    background: #fff;
    border-bottom: 1px solid #00000014;

    &:last-child {
        border-bottom: none;
    }
}

.Exception-break {
    .table-tr-custom {
        td {
            &:nth-child(3) {
                // word-break: break-all !important;
                // white-space: normal;
                width: 10% !important;
            }
        }
    }
}

.Exception-break {
    table {
        thead {
            th {
                &:nth-child(6) .font-semibold {
                    text-align: center !important;
                }
            }
        }
    }
    .table-tr-custom {
        td {
            &:nth-child(6) {
                text-align: center !important;
            }
        }
    }
}

.Exception-break {
    .table-tr-custom {
        td {
            &:nth-child(4) {
                // word-break: break-all !important;
                white-space: normal;
            }
        }
    }
}

.view-product {
    .table-tr-custom {
        td {
            &:nth-child(5) {
                word-break: unset !important;
                white-space: normal;
            }
        }
    }
}

.max-w-9xl {
    max-width: 118rem !important;
}

/* Custom-Scrollbar Start */
::-webkit-scrollbar {
    width: 4px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--commanColor);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #8db5e0;
}

/* Custom-Scrollbar End */
.sidemenucolor {
    --tw-bg-opacity: 1;
    background-color: #00769f;
}

.bcolor {
    background-color: #00769f;
    // nd-color: #11769f;
    color: #fff;
}

// Shopping cart Start
.cart_item {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding: 10px 0;
}

.cart-image1 img {
    max-width: 100%;
    margin-right: 20px;
}

.product-availability {
    margin: 10px auto 0;
    margin-left: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    width: 95px;
    z-index: 1;
}

.stockStatus {
    font-weight: 500;
    font-size: 12px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    width: 100%;
}

.stockStatus i {
    margin-top: 1px !important;
    padding-left: 10px !important;
    padding-right: 0.5rem !important;
    padding-top: 0.3rem !important;
    padding-bottom: 0.3rem !important;
}

.product-availability .stockStatus.in-stock {
    background: #5aab19;
    color: #fff;
}

.q_section {
    -webkit-flex: 0 0 30%;
    flex: 0 0 30%;
    min-width: 50%;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.product-title {
    color: #332d97;
    font-weight: bold;
}

.card_details p {
    white-space: nowrap;
    margin-bottom: 5px;
}

.card_details p label {
    display: inline-block;
    font-weight: 600;
    color: #000;
    position: relative;
    text-transform: capitalize;
}

.card_details span {
    font-weight: bold;
    padding: 0;
}

.card_details .ml-auto {
    margin-left: auto !important;
}

.py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.btnsign {
    padding: 11px;
}

.float-right1 {
    // float: center;
    justify-content: center;
    // margin-top: 10px;
    // margin: 0.5rem 0rem 0rem -19rem;
}

.step {
    color: #6366f1;
}

.buttonDataImport {
    min-width: 4rem;
}

.marginRight {
    margin-right: 1.5rem;
}

.overflow {
    position: absolute;
}

.product-quantity {
    border-radius: 5px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    -webkit-box-pack: end;
    -webkit-justify-content: end;
    -ms-flex-pack: end;
    justify-content: end;
}

.product-quantity input {
    margin: 0 0.7rem;
}

.image {
    max-width: 73%;
    height: auto;
}

.justify-between1 {
    justify-content: center;
}

.rfq-detail-list {
    .email-mark-icon {
        border-radius: 50px 0px 0px 50px;
        -webkit-border-radius: 50px 0px 0px 50px;
        -moz-border-radius: 50px 0px 0px 50px;
        -ms-border-radius: 50px 0px 0px 50px;
        -o-border-radius: 50px 0px 0px 50px;
    }
}

input,
select {
    height: 44px;
}

.no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

.backorder {
    margin-right: -4px;
}

.bg-color-1 {
    color: #11769f;
    // color: #fff;
}

.minWidth {
    min-width: 8rem;
}

.card-absolute {
    position: absolute;
    top: -25px;
    background: #fff;
    border-radius: 15px;
    padding: 13px;
    left: 39px;
    box-shadow: 0px 5px 20px -7px #265199;
    width: 75px;
    height: 75px;
    display: flex;
    align-items: center !important;
    justify-content: center !important;
}

.card-absolute svg path {
    fill: var(--commanColorHover) !important;
}

.card-absolute i {
    color: var(--commanColorHover) !important;
}

.header-backgroung-color {
    background-color: #fff;
}

// .ui-width {
//     max-width: 500px;
// }

.first-card {
    border-radius: 10px;
    border: none;
    box-shadow: 0px 5px 20px -7px #265199;
    position: relative;
}

.counter-num {
    margin-right: 30px;
    font-size: 30px;
}

.title-counter {
    font-size: 20px;
    color: #1e293b;
    padding-top: 20px;
    padding-left: 40px;
}

.pagination-remove .pagination-table {
    display: none;
}

.fixed_header_dashboard {
    display: block;
    max-height: 450px;
    overflow: auto;
    width: 100%;
    padding: 0px !important;
    margin-top: 0px !important;
}

.themeColor {
    color: var(--commanColorHover);
}

.themeColor-hover {
    color: #ff7300;
}

.sync-button {
    padding: 10px 20px;
    background-color: var(--commanColor);
    color: white;
    border: none;
    border-radius: 2px;
    cursor: pointer;
}

// .sync-button:hover {
//     background-color: #11769f;
// }
// .text-color-indigo-500{
//     color: #ff9900;
// }
.activeColor {
    background-color: rgb(32 168 60 / var(--tw-bg-opacity));
    color: #fff;
}

.Search-height {
    height: 2.5rem;
}

.DataImport-btn {
    float: right;
    margin-right: 1.5rem;
}

// .jsCon{
//     justify-content: end;
// }

// .jsCon{
//     justify-content: center;
// }
.username-text {
    color: black !important;
    cursor: pointer !important;
}

.colorSideMenu {
    font-size: 15px;
    color: #fff;
}

.colorSideMenu1 {
    // font-size: 17px;
    color: #fff;
}

.aCircule {
    height: 2rem;
}

.Dashbord-ml {
    margin-left: 1rem;
}

.menu-toggle::after {
    content: "";
    position: absolute;
    top: 50%;
    display: block;
    width: 0.42em;
    height: 0.42em;
    border: 1px solid;
    border-bottom: 0;
    border-left: 0;
    transform: translateY(-50%) rotate(45deg);
}

.hightData {
    height: 2.5rem;
}

.unauthorizecard {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.unauthorizecard button {
    padding: 12px 30px;
}

.fixed_header_dashboard {
    display: block;
    max-height: 450px;
    overflow: auto;
    width: 100%;
    padding: 0px !important;
    margin-top: 0px !important;
}

.shadowColor {
    border-radius: 0px !important;
    padding: 0px !important;
    margin-top: 0px !important;
    background-color: #fff;
}

.card-label-sec .space-beetwin-sec .label-section-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-label-secc .space-beetwin-sec .label-section-main {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.card-label-sec .label-section-main {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
    padding-left: 0;
    padding-right: 0.5rem;
    border-bottom: 1px solid rgb(241, 245, 249);
    margin: 0 0.5rem;
}

.card-label-sec .shippingfont {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
    padding-left: 0;
    padding-right: 0.5rem;
    border-bottom: 1px solid rgb(241, 245, 249);
    margin: 0 0.5rem;
}

.card-label-sec .label-section-main label {
    margin: 0;
    font-size: 0.875rem !important;
    line-height: 1.5715 !important;
}

.card-label-secc .label-section-main label {
    margin: 0;
    font-size: 0.875rem !important;
    line-height: 2rem !important;
}

.fw-bold {
    font-weight: bold;
}

.card-label-sec .shippingfont {
    font-size: 14.4px !important;
    margin: 0px 8px;
    padding: 8px 8px 8px 0px;
    word-break: break-all;
}

card-label-sec {
    .label-section-main {
        padding-bottom: 0.5rem !important;
        padding-top: 0.5rem !important;
        margin-bottom: 0;
        padding-left: 0;
        padding-right: 0.5rem;
        border-bottom: 1px solid rgb(241 245 249);
        margin: 0 0.5rem;

        label {
            margin: 0;
            font-size: 0.875rem !important;
            line-height: 1.5715 !important;
        }
    }

    .self-center {
        padding-bottom: 0.5rem !important;
        padding-top: 0.5rem !important;
        margin-bottom: 0;
        padding-left: 0rem;
        padding-right: 1rem;
        margin: 0 0.5rem;
        border-bottom: 1px solid rgb(241 245 249);
        font-size: 0.875rem !important;
        line-height: 1.5715;
    }

    .space-beetwin-sec {
        .label-section-main {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:last-child() {
                border: none;
            }
        }

        &.border-none {
            .label-section-main {
                border: none;
            }
        }
    }
}

.search-bar-input {
    input {
        padding-left: 35px;
    }
}

.view-product-table {
    .data-not-found-tbody {
        tr {
            td {
                text-align: center !important;
            }
        }
    }
    table {
        thead {
            th {
                &:nth-child(4) .font-semibold {
                    text-align: center !important;
                }
            }
        }
    }
    .table-tr-custom {
        td {
            &:nth-child(4) {
                // word-break: break-all !important;
                // white-space: normal;
                text-align: center !important;
            }
        }
    }
    // table {
    //     thead {
    //         th {
    //             &:nth-child(5) .font-semibold {
    //                 text-align: center !important;
    //             }
    //         }
    //     }
    // }
    // .table-tr-custom {
    //     td {
    //         &:nth-child(5) {
    //             // word-break: break-all !important;
    //             // white-space: normal;
    //             text-align: center !important;
    //         }
    //     }
    // }
}

thead {
    th {
        &:last-child {
            div {
                text-align: center !important;
            }
        }
    }
}

tr {
    td {
        &:last-child {
            text-align: center !important;
        }
    }
}

//   .tooltip-container {
//       cursor: pointer;
//       position: relative;
//       display: inline-block;
//   }

//   .tooltips {
//       opacity: 0;
//       z-index: 99;
//       color: rgb(255, 255, 255);
//       width: auto;
//       display: block;
//       font-size: 11px;
//       padding: 5px 10px;
//       border-radius: 3px;
//       text-align: center;
//       text-shadow: 1px 1px 2px #111;
//       background: rgba(51,51,51,0.9);
//       border: 1px solid rgba(34,34,34,0.9);
//       box-shadow: 0 0 3px rgba(0,0,0,0.5);
//       -webkit-transition: all .2s ease-in-out;
//       -moz-transition: all .2s ease-in-out;
//       -o-transition: all .2s ease-in-out;
//       -ms-transition: all .2s ease-in-out;
//       transition: all .2s ease-in-out;
//       -webkit-transform: scale(0);
//       -moz-transform: scale(0);
//       -o-transform: scale(0);
//       -ms-transform: scale(0);
//       transform: scale(0);
//       position: absolute;
//       right: -69px;
//       bottom: 35px;
//   }
//   .tooltip {
//       opacity: 0;
//       z-index: 99;
//       color: rgb(255, 255, 255);
//       width: auto;
//       display: block;
//       font-size: 11px;
//       padding: 5px 10px;
//       border-radius: 3px;
//       text-align: center;
//       text-shadow: 1px 1px 2px #111;
//       background: rgba(51,51,51,0.9);
//       border: 1px solid rgba(34,34,34,0.9);
//       box-shadow: 0 0 3px rgba(0,0,0,0.5);
//       -webkit-transition: all .2s ease-in-out;
//       -moz-transition: all .2s ease-in-out;
//       -o-transition: all .2s ease-in-out;
//       -ms-transition: all .2s ease-in-out;
//       transition: all .2s ease-in-out;
//       -webkit-transform: scale(0);
//       -moz-transform: scale(0);
//       -o-transform: scale(0);
//       -ms-transform: scale(0);
//       transform: scale(0);
//       position: absolute;
//       right: -125px;
//       bottom: 35px;
//   }

//   .tooltip:before,.tooltip:after {
//       content: '';
//       border-left: 10px solid transparent;
//       border-right: 10px solid transparent;
//       border-top: 10px solid rgba(51,51,51,0.9);
//       position: absolute;
//       bottom: -10px;
//       left: 44%;
//   }
//   .tooltips:before,.tooltips:after {
//       content: '';
//       border-left: 10px solid transparent;
//       border-right: 10px solid transparent;
//       border-top: 10px solid rgba(51,51,51,0.9);
//       position: absolute;
//       bottom: -10px;
//       left: 44%;
//   }

//   .tooltip-container:hover .tooltip,a:hover .tooltip {
//       opacity: 1;
//       -webkit-transform: scale(1);
//       -moz-transform: scale(1);
//       -o-transform: scale(1);
//       -ms-transform: scale(1);
//       transform: scale(1);
//   }
//   .tooltips-container:hover .tooltips,a:hover .tooltips {
//       opacity: 1;
//       -webkit-transform: scale(1);
//       -moz-transform: scale(1);
//       -o-transform: scale(1);
//       -ms-transform: scale(1);
//       transform: scale(1);
//   }
.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

/* Define the tooltip text style */
.tooltip .tooltiptext {
    visibility: hidden;
    background-color: #0F1E35;
    /* Tooltip background color */
    color: #fff;
    /* Tooltip text color */
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.2s;
}

/* Show the tooltip on hover */
.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

.tooltipp {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

/* Define the tooltip text style */
.tooltipp .tooltipptext {
    visibility: hidden;
    background-color: #0F1E35;
    /* Tooltip background color */
    color: #fff;
    /* Tooltip text color */
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.2s;
}

/* Show the tooltip on hover */
.tooltipp:hover .tooltipptext {
    visibility: visible;
    opacity: 1;
}

.sync_count {
    padding: 9px 3px;
}

.loader {
    border: 5px solid #ffffff;
    border-radius: 50%;
    border-top: 5px solid #00769f;
    width: 20px;
    height: 20px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

.tooltipp .tooltipptextUserActivity {
    visibility: hidden;
    background-color: #0F1E35; /* Tooltip background color */
    color: #fff; /* Tooltip text color */
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: -58px;
    // left: 130px;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.2s;
}

.tooltipptextUserActivity::after {
    content: "";
    position: absolute;
    top: 50px;
    left: 50%;
    border-width: 9px;
    border-style: solid;
    border-color: #0F1E35 transparent transparent transparent;
    transform: translateX(-50%) rotate(0deg);
}

/* Show the tooltip on hover */
.tooltipp:hover .tooltipptextUserActivity {
    visibility: visible;
    opacity: 1;
}

.margin-bottom-0 {
    margin-bottom: -2px !important;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@media (max-width: 767px) {
    .SerchBox-center1 {
        margin-left: 4.5rem;

        .ui-width {
            max-width: 500px;
        }
    }

    .ml-shoppingCart {
        margin-left: 5rem;
    }
}

@media (max-width: 767px) {
    .mobile-logo {
        margin-bottom: 10px;
    }

    .mobile-overflow {
        overflow-y: auto;
    }

    .btn-centerMV {
        margin-right: 1rem;
    }

    .DataImport {
        margin-right: 6rem;
    }
}

// Custom-Email-style Start

.email-template-cuctom-styles {
}

.float-right2 {
    display: flex;
    justify-content: end;
}

.product-details-img img {
    margin: 35px auto 0;
}

.Price-for {
    .table-tr-custom {
        td {
            &:nth-child(7) {
                text-align: right;
            }
        }
    }
}

.price_formate {
    .table-tr-custom {
        td {
            &:nth-child(4) {
                text-align: center;
            }
        }
    }
}

.price_formate {
    .table-tr-custom {
        td {
            &:nth-child(5) {
                text-align: center;
            }
        }
    }
}

.Price-forr {
    .table-tr-custom {
        td {
            &:nth-child(7) {
                text-align: end;
            }
        }
    }
}

.Price-forr-p {
    .table-tr-custom {
        td {
            &:nth-child(5) {
                text-align: end;
            }
        }
    }
}

.Price-forr-p {
    table {
        thead {
            th {
                &:nth-child(5) .font-semibold {
                    text-align: end !important;
                }
            }
        }
    }
}

.Price-forr {
    table {
        thead {
            th {
                &:nth-child(7) .font-semibold {
                    text-align: end !important;
                }
            }
        }
    }
}

.Price-forr-rfq {
    .table-tr-custom {
        td {
            &:nth-child(3) {
                text-align: end !important;
            }
        }
    }
}

.Price-forr-rfq {
    table {
        thead {
            th {
                &:nth-child(3) .font-semibold {
                    text-align: end !important;
                }
            }
        }
    }
}

.price-formate {
    table {
        thead {
            th {
                &:nth-child(6) .font-semibold {
                    text-align: end !important;
                }
            }
        }
    }
    .table-tr-custom {
        td {
            &:nth-child(6) {
                text-align: end !important;
            }
        }
    }
}

.shopping_details {
    display: flex;
    justify-content: space-between;
}

.reports-button {
    display: flex;
    gap: 2rem;
    justify-content: center;
    margin-top: 2.5rem;
}

.reports-button button {
    padding: 13px !important;
}

.date_select {
    font-weight: 600;
}

.productName-text {
    color: #000 !important;
}

.archive_color {
    color: #000 !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.fa-info-circle {
    font-size: 20px;
}

.Exception_fill {
    float: right;
    position: relative;
    top: -33px;
    left: -20px;
    cursor: pointer;
}

.Exception_fill path {
    padding: 0 8px !important;
}

.exception_close .text-slate-400 {
    display: none;
}

.IpDetails {
    display: flex;
    justify-content: space-between;
}

.activity_details {
    position: relative;
    top: 0rem;
}

.activity_details_flex {
    display: flex;
    gap: 8rem;
}

.fa-angle-down,
.fa-angle-up {
    font-size: 18px;
    font-weight: 800 !important;
    position: relative;
    top: 0.1rem;
    left: 0.5rem;
}

.sidebar-childSubMenus {
    position: relative;
    left: -1rem;
}

.shoppingCartdetails {
    background-color: #f0f8ff;
    font-weight: bold;
    font-size: 15px;
}

.shoppingCartdetails label {
    color: #1e293b;
    font-weight: 500;
}

.productdashboard {
    box-shadow: 0 0px 14px -7px var(--commanColor) !important;
}

.ActivityDetails {
    display: flex;
    gap: 8rem;
}

.useractivitydetails label {
    font-weight: 600;
}

.useractivitydetailsException label {
    font-weight: 600;
    white-space: pre;
}

.useractivitydetails,
.useractivitydetailsException {
    background-color: #f7fdff;
    border: 1px solid #dcf6ff;
}

.ActivityDetails {
    display: flex;
    gap: 8rem;
}

.useractivitydetails,
.useractivitydetailsException span {
    word-break: break-all !important;
}

.ExceptionUpdate {
    display: flex;
    justify-content: end !important;
}

.stock_icon,
.safty_icon {
    display: flex;
    gap: 10px !important;
    justify-content: center;
}

.Bars_details {
    position: relative;
    bottom: 80px;
}

.RfqDetails-Notfound {
    background: linear-gradient(360deg, #162b33 0%, #008ebf 100%);
    padding: 20px 50px;
    font-size: large;
    color: #fff;
    font-weight: 500;
    text-align: center;
    position: relative;
    top: 50px;
}

.tab-background2 {
    background: var(--commanColor) !important;
    color: #fff !important;
}

.form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: 0.5;
}

.address_word_breaking {
    word-break: break-all;
}

.fa-trash,
.fa-check.themeColor,
.fa-edit {
    color: var(--commanColor) !important;
}

.productDashboard {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.productDashboard label {
    font-weight: 500;
    font-size: 20px;
}

.shadow-lg-productCard {
    box-shadow: 0 0px 14px -7px var(--commanColor) !important;
    cursor: pointer;
}

.product-form {
    position: relative;
    top: 2rem;
}

// under-construction-section
.under-construction-section {
    height: calc(100vh - 65px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .card {
        width: 100%;
        height: 100%;

        .card-body {
        }
    }
}

.Dashboard-card-count {
    cursor: pointer !important;
}

// Widget Section

.chart-main-dec {
    padding: 0 25px;
    margin-bottom: 30px;

    div {
        width: 75%;
        margin: 0 auto;
    }
}

.react-datepicker-wrapper {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        background: url(../../public/date-pic-icon.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        top: 50%;
        right: 26px;
        transform: translateY(-50%);
        height: 20px;
        width: 20px;
        z-index: 1;
    }
}

// Accordion Section Start
.accordion-section {
    .accordion-header {
        .button-title-action {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 15px 0 0;
            background: var(--commanColorHoverLight);
            button {
                width: 4%;
                width: 40px;
                height: 40px;
                padding: 0;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .title-action {
                width: 96%;
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .wizard-title {
                    width: 55%;
                    font-size: 100%;
                }

                .wizard-count {
                    width: 45%;
                    display: flex;
                    align-items: center;
                    justify-content: end;

                    h4 {
                        margin-bottom: 0;
                        margin-right: 20px;
                    }

                    .active-inactive {
                        margin-right: 20px;
                        min-width: 100px;
                        text-align: center;
                        span {
                            font-size: 85%;
                            padding: 2px 10px 3px;
                            border-radius: 30px;
                            -webkit-border-radius: 30px;
                            -moz-border-radius: 30px;
                            -ms-border-radius: 30px;
                            -o-border-radius: 30px;
                            color: #fff;
                            margin: 0 10px;

                            &.inActiveColor {
                                background: #c00000;
                            }
                        }
                    }

                    .action-btn {
                        display: flex;
                        align-items: center;

                        button {
                            width: auto;
                            margin-right: 20px;

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
            &.active-button {
                background: var(--commanColor);
                color: #fff !important;
                i {
                    color: #fff !important;
                }
            }
        }
    }
}

// Accordion Section End

// Dashboard Page Start
.custom-tab-section {
    .tab-contents {
        .tab-btns {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .tab-btn-link {
                display: flex;

                .tab-link {
                    font-weight: 600;
                    margin-right: 5px;
                    padding: 5px 20px;
                    border: 1px solid #fff;
                    border-bottom: 0;
                    border-radius: 5px;
                    -webkit-border-radius: 5px;
                    -moz-border-radius: 5px;
                    -ms-border-radius: 5px;
                    -o-border-radius: 5px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    background: #fff;
                    transition: 0.5s all;
                    -webkit-transition: 0.5s all;
                    -moz-transition: 0.5s all;
                    -ms-transition: 0.5s all;
                    -o-transition: 0.5s all;

                    &.active-tab-link {
                        background: var(--commanColoricon);
                        color: var(--whiteColor);
                        border-color: var(--commanColoricon);

                        &:hover {
                            background: var(--commanColoricon);
                            color: var(--whiteColor);
                            border-color: var(--commanColoricon);
                        }
                    }

                    &:hover {
                        background: var(--commanColorHover);
                        color: var(--commanColoricon);
                        border-color: var(--commanColorHover);
                    }
                }
            }
        }
    }

    .user-details-section {
        padding: 0 10px;

        .widgets-section {
            .center-div {
                background: #fff;
                position: relative;
                border-radius: 5px;
                overflow: hidden;
                height: max-content;

                &.card-bg-light {
                    background: #fff;
                }

                .card-title {
                    padding: 7px 10px;
                    background: #0f1e35;
                    font-weight: 600;
                    font-size: 105%;
                    color: var(--whiteColor);
                }
                canvas {
                    padding: 0 10px 10px;
                    background: #fff;
                }

                caption {
                    position: sticky;
                    top: 0;
                    min-width: 100%;
                    display: block;

                    .tab-title {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        font-weight: 600;
                        font-size: 105%;
                        color: var(--whiteColor);

                        .tab-title-txt {
                            font-weight: 600;
                            font-size: 105%;
                        }
                    }
                }

                .table-sec {
                    overflow: auto;
                    max-height: 350px;
                    table {
                        overflow: auto;
                    }
                }
            }
        }
    }
}

// Dashboard Page End

.exportLink {
    label {
        width: 160px;
        display: flex;
        font-weight: bolder;
        // align-items: center;
    }
}

.sticky-container {
    height: calc(100vh - 200px);
    overflow: hidden;
    overflow-y: auto;
}

.pad-0-new{
    padding: 0 !important;
}

.exportLink .form-input{
    padding: 3px;
    height: 30px;
}

.search-history-new table thead tr th:nth-child(3),
.search-history-new table tbody tr td:nth-child(3) {
    text-align: center !important;
    width: 30% !important;
    white-space: normal;
}
 
.search-history-new table thead tr th:nth-child(3) div {
    text-align: center;
}
