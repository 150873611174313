.form-input,
.form-textarea,
.form-multiselect,
.form-select,
.form-checkbox,
.form-radio {

    &:focus {
        @apply ring-0;
    }
}

.p10{
    .cke_contents{
        min-height: 700px !important
    }
}
 .line-elpsis{
    
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
   overflow: hidden;

}